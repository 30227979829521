@font-face {
  font-family: 'PF Regal';
  font-style: normal;
  src: url('../fonts/PFRegal/PFRegalTextPro-RegularB.woff') format('woff'); /* Modern Browsers */
}

@font-face {
  font-family: 'PF Regal';
  font-style: italic;
  src: url('../fonts/PFRegal/PFRegalTextPro-RegularBItalic.woff') format('woff'); /* Modern Browsers */
}

@font-face {
  font-weight: bold;
  font-family: 'PF Regal';
  src: url('../fonts/PFRegal/PFRegalTextPro-Bold.woff') format('woff'); /* Modern Browsers */
}

@font-face {
  font-weight: bold;
  font-family: 'PF Regal';
  font-style: italic;
  src: url('../fonts/PFRegal/PFRegalTextPro-BoldItalic.woff') format('woff'); /* Modern Browsers */
}

@font-face {
  font-weight: 400;
  font-family: 'Proxima Nova';
  font-style: normal;
  src: url('../fonts/ProximaNova/ProximaNova-Regular.woff') format('woff');
}

@font-face {
  font-weight: 600;
  font-family: 'Proxima Nova';
  font-style: normal;
  src: url('../fonts/ProximaNova/ProximaNova-Semibold.woff') format('woff');
}

@font-face {
  font-weight: 700;
  font-family: 'Proxima Nova';
  font-style: normal;
  src: url('../fonts/ProximaNova/ProximaNova-Bold.woff') format('woff');
}
